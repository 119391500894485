// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-about-us-our-business-model-jsx": () => import("./../../../src/pages/about-us/our-business-model.jsx" /* webpackChunkName: "component---src-pages-about-us-our-business-model-jsx" */),
  "component---src-pages-about-us-our-purpose-and-values-jsx": () => import("./../../../src/pages/about-us/our-purpose-and-values.jsx" /* webpackChunkName: "component---src-pages-about-us-our-purpose-and-values-jsx" */),
  "component---src-pages-about-us-our-team-jsx": () => import("./../../../src/pages/about-us/our-team.jsx" /* webpackChunkName: "component---src-pages-about-us-our-team-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-corporate-index-jsx": () => import("./../../../src/pages/corporate/index.jsx" /* webpackChunkName: "component---src-pages-corporate-index-jsx" */),
  "component---src-pages-esg-jsx": () => import("./../../../src/pages/esg.jsx" /* webpackChunkName: "component---src-pages-esg-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-agm-jsx": () => import("./../../../src/pages/investors/AGM.jsx" /* webpackChunkName: "component---src-pages-investors-agm-jsx" */),
  "component---src-pages-investors-analyst-coverage-jsx": () => import("./../../../src/pages/investors/analyst-coverage.jsx" /* webpackChunkName: "component---src-pages-investors-analyst-coverage-jsx" */),
  "component---src-pages-investors-index-jsx": () => import("./../../../src/pages/investors/index.jsx" /* webpackChunkName: "component---src-pages-investors-index-jsx" */),
  "component---src-pages-investors-investing-with-us-jsx": () => import("./../../../src/pages/investors/investing-with-us.jsx" /* webpackChunkName: "component---src-pages-investors-investing-with-us-jsx" */),
  "component---src-pages-investors-presentations-and-fact-sheets-jsx": () => import("./../../../src/pages/investors/presentations-and-fact-sheets.jsx" /* webpackChunkName: "component---src-pages-investors-presentations-and-fact-sheets-jsx" */),
  "component---src-pages-investors-us-investors-jsx": () => import("./../../../src/pages/investors/us-investors.jsx" /* webpackChunkName: "component---src-pages-investors-us-investors-jsx" */),
  "component---src-pages-media-releases-jsx": () => import("./../../../src/pages/media-releases.jsx" /* webpackChunkName: "component---src-pages-media-releases-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-oko-west-jsx": () => import("./../../../src/pages/oko-west.jsx" /* webpackChunkName: "component---src-pages-oko-west-jsx" */),
  "component---src-pages-reunion-gold-acquisition-jsx": () => import("./../../../src/pages/reunion-gold-acquisition.jsx" /* webpackChunkName: "component---src-pages-reunion-gold-acquisition-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-tocantinzinho-jsx": () => import("./../../../src/pages/tocantinzinho.jsx" /* webpackChunkName: "component---src-pages-tocantinzinho-jsx" */),
  "component---src-templates-media-updates-jsx": () => import("./../../../src/templates/media-updates.jsx" /* webpackChunkName: "component---src-templates-media-updates-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */)
}

